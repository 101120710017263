

/* Smooth scrolling applied via JavaScript in the component */

/* Custom scrollbar styles */
:global(::-webkit-scrollbar) {
  width: 12px;
}

:global(::-webkit-scrollbar-track) {
  background: #f1f1f1;
  border-radius: 10px;
}

:global(::-webkit-scrollbar-thumb) {
  background: #888;
  border-radius: 10px;
  transition: background 0.3s ease-in-out;
}

:global(::-webkit-scrollbar-thumb:hover) {
  background: #555;
}

/* For Firefox */
:global(*) {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Fade-in effect */
.fade-in {
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}