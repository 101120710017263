.mainContent {
    background-color: rgb(248, 248, 248);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.container {
    background-color: #3A9AF9;
    border-radius: 21px;
    padding: 4rem;
    margin-top: -10rem;
    width: 80%;
    display: flex;
    justify-content: center;
    align-self: center;
    transform-origin: bottom;
    transition: transform 0.3s ease-out;
    will-change: transform;
    position: relative;
    z-index: 2;
}

.contentWrapper {
    display: flex;
    gap: 20px;
    max-width: 1002px;
    margin: 0 auto;
}

.imageColumn {
    flex: 0 0 56%;
}

.mainImage {
    width: 100%;
    height: auto;
    border-radius: 0;
}

.textColumn {
    flex: 0 0 44%;
    display: flex;
    flex-direction: column;
}

.heading {
    color: #fff;
    font-size: 42px;
    line-height: 1.2;
    letter-spacing: -0.85px;
    font-weight: 600;
    margin-bottom: 20px;
}

.subheading {
    color: #f1f5f9;
    font-size: 21px;
    line-height: 1.5;
    margin-bottom: 30px;
}

.ctaButton {
    border-radius: 31px;
    background-color: #005477;
    color: #fff;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.97px;
    padding: 20px 40px;
    border: none;
    cursor: pointer;
    align-self: flex-start;
}

.featuresSection {
    margin-top: 77px;
    padding: 0 80px;
}

.whyChooseSection {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 35rem;
    position: relative;
    border-radius: 15px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.circleImg {
    position: absolute;
    z-index: -1;
    top: 10;
    left: 0;
    width: 90%;
    height: 35rem;
    background-image: url('../../../circle.png');
    background-repeat: no-repeat;
}

.whyChooseContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4rem;
}

.whyChooseImageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
}

.whyChooseImage {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.featuresTitle {
    font-size: 62px;
    line-height: 1.2;
    letter-spacing: -2.5px;
    text-align: left;
    color: #005477;
    font-weight: 400;
    margin-bottom: 20px;
}

.featuresDescription {
    line-height: 1.5;
    max-width: 600px;
    text-align: left;
}

.featureCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: -1rem;
}

.animatedSection {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.animatedSection.visible {
    opacity: 1;
}

@media (max-width: 1200px) {
    .whyChooseSection {
        padding: 3rem;
    }

    .featuresTitle {
        font-size: 52px;
    }
}

@media (max-width: 991px) {
    .container {
        padding: 3rem 2rem;
        width: 90%;
        margin-top: -10rem;
    }

    .contentWrapper {
        flex-direction: column;
    }

    .imageColumn,
    .textColumn {
        flex: 0 0 100%;
    }

    .mainImage {
        margin-bottom: 20px;
    }

    .heading {
        font-size: 36px;
    }

    .subheading {
        font-size: 18px;
    }

    .ctaButton {
        font-size: 20px;
        padding: 15px 30px;
        align-self: center;
    }

    .featuresSection {
        padding: 0;
    }

    .whyChooseSection {
        flex-direction: column;
        height: auto;
        width: 100%;
        border-radius: 0;
        padding: 2rem 0;
    }

    .whyChooseContent,
    .whyChooseImageContainer {
        padding: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .whyChooseContent {
        text-align: center;
    }

    .whyChooseImageContainer {
        margin-top: 0;
    }

    .featuresTitle {
        font-size: 40px;
        text-align: center;
    }

    .featuresDescription {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .featureCards {
        grid-template-columns: 1fr;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 768px) {
    .container {
        width: 80%;
        padding: 2rem;
        margin-top: -6rem;
        transform: none !important;
        display: block;
        border-radius: 15px;
    }

    .heading {
        font-size: 28px;
        text-align: center;
    }

    .subheading {
        font-size: 16px;
        text-align: center;
    }

    .ctaButton {
        font-size: 18px;
        padding: 12px 24px;
        align-self: center;
    }

    .whyChooseSection {
        width: 100%;
        border-radius: 0;
    }

    .whyChooseContent,
    .whyChooseImageContainer {
        margin: -4rem;
    }

    .featuresTitle {
        font-size: 32px;
    }

    .featuresDescription {
        font-size: 14px;
    }

    .circleImg {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .container {
        margin-top: 1rem;
    }
}

@media (prefers-reduced-motion: reduce) {
    .animatedSection,
    .whyChooseSection,
    .container {
        transition: none;
        transform: none !important;
    }
}