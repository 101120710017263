.testimonials {
    margin-top: 77px;
    padding: 0 80px;
  }
  
  .title {
    font-size: 47px;
    line-height: 59px;
    text-align: center;
    color: #000;
    letter-spacing: -1.9px;
    margin-bottom: 71px;
  }
  
  .testimonialGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .testimonialImage {
    width: 100%;
    height: auto;
    border-radius: 21px;
    aspect-ratio: 0.56;
    object-fit:contain;
  }
  
  @media (max-width: 991px) {
    .testimonials {
      padding: 0 20px;
      margin-top: 40px;
    }
  
    .title {
      font-size: 40px;
      line-height: 51px;
      margin-bottom: 40px;
    }
  
    .testimonialGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }