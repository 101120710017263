.footer {
    background: #005477;
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
}

.footerHeading {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.downloadButton {
    display: flex;
    justify-content: center;
    background-color: #015db8;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.downloadButton:hover {
    background-color: #e6e6e6;
    color: #2a7ac7;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.footerHeading::after {
    content: '↗';
    margin-left: 8px;
    font-size: 18px;
}

/* WhatsApp Button Styles */
.whatsappButton {
    position: fixed;
    right: 20px;
    bottom: 100px; /* Positioned above the footer */
    width: 60px;
    height: 60px;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsappButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.whatsappButton svg {
    width: 32px;
    height: 32px;
}

/* Add margin to the main content to prevent footer overlap */
:global(main) {
    margin-bottom: 90px;
}

@media (max-width: 1200px) {
    .footer {
        padding: 15px 30px;
        padding-bottom: calc(15px + env(safe-area-inset-bottom));
    }
}

@media (max-width: 991px) {
    .footer {
        flex-direction: column;
        gap: 15px;
        text-align: center;
        padding: 15px;
        padding-bottom: calc(15px + env(safe-area-inset-bottom));
    }

    .whatsappButton {
        bottom: 90px;
        width: 50px;
        height: 50px;
    }

    .whatsappButton svg {
        width: 28px;
        height: 28px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 12px;
        padding-bottom: calc(12px + env(safe-area-inset-bottom));
    }
    
    .downloadButton {
        font-size: 15px;
        padding: 10px 20px;
    }
    
    .footerHeading {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 10px;
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
    }
    
    .footerHeading {
        font-size: 18px;
    }
    
    .downloadButton {
        width: 100%;
        font-size: 14px;
        padding: 8px 16px;
    }

    .whatsappButton {
        width: 60px;
        height: 60px;
        right: 13px;
        bottom: 100px;
    }

    .whatsappButton svg {
        width: 24px;
        height: 24px;
    }
}