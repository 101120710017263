.hero {
  padding: 6rem 1rem 2rem;
  background: linear-gradient(to bottom, #ffffff, #f8fafc);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.content {
  max-width: 100%;
  z-index: 2;
}

.title {
  font-size: clamp(2rem, 5vw + 1rem, 3.5rem);
  line-height: 1.1;
  color: #1B4B66;
  font-weight: 700;
  margin-bottom: 1rem;
  position: relative;
}

.description {
  font-size: clamp(1rem, 1.5vw, 1.125rem);
  line-height: 1.6;
  color: #4A4A4A;
  margin: 0 auto 2rem;
  max-width: 42rem;
  padding: 0 1rem;
}

.button {
  background: #54A7F9;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.2s ease;
}

.button:hover {
  background: #3d96f0;
  transform: translateY(-1px);
}

.phones {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 90vw;
  place-items: center;
}

.phoneContainer {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.phoneMain {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.1));
}

.phoneSecondary {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.08));
}

@media (min-width: 640px) {
  .hero {
    padding: 7rem 2rem 3rem;
  }

  .phones {
    max-width: 800px;
  }
}

@media (min-width: 1024px) {
  .hero {
    padding: 8rem 5rem 4rem;
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem;
  }

  .content {
    flex: 1;
    max-width: 600px;
  }

  .description {
    margin: 0 0 2rem;
    padding: 0;
  }

  .phones {
    flex: 1;
    margin-top: 0;
  }
}

.footer {
  background: #1B4B66;
  padding: 1.5rem;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footerButton {
  background: #54A7F9;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 640px) {
  .footer {
    flex-direction: column;
    gap: 1rem;
  }
}