.hero {
  position: relative;
  overflow: visible;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #005477;
  margin-top: 3rem;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContent {
  text-align: center;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}

.title {
  font-size: 32px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.downloadButton {
  background-color: #4A90E2;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.phoneImage {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.backgroundShape {
  display: none;
}

@media (min-width: 768px) {
  .hero {
   margin-bottom: -10rem;
  }

  .heroContent {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .textContent {
    max-width: 45%;
    text-align: left;
  }

  .title {
    font-size: 72px;
    margin-bottom: 20px;
  }

  .description {
    font-size: 24px;
    max-width: 466px;
  }

  .downloadButton {
    display: none;
  }

  .imageContainer {
    flex: 1;
    justify-content: flex-end;
  }

  .phoneImage {
    width: 370px;
    max-width: none;
  }

  .backgroundShape {
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    right: -10%;
    top: 55%;
    transform: translateY(-50%);
    background: url('https://models-upload.s3.ap-south-1.amazonaws.com/bg1.jpeg');
    opacity: 0.2;
    mix-blend-mode: color-dodge;
  }
}