.featureCard {
  border-radius: 23.647px;
  background: rgba(234, 234, 234, 0.30);
  backdrop-filter: blur(2.0611157417297363px);
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  overflow: hidden;
}

.featureCard.animate {
  opacity: 1;
  transform: translateY(0);
}

.featureCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  background-color: #b9b9b9;
}

.featureImage {
  width: 40%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.featureCard:hover .featureImage {
  transform: scale(1.05);
}

.featureContent {
  width: 60%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.featureTitle {
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
  margin-bottom: 5px;
  transition: color 0.3s ease-in-out;
}

.featureCard:hover .featureTitle {
  color: #005477;
}

.featureDescription {
  font-size: 12px;
  line-height: 1.3;
  color: #6c6c6c;
  margin-bottom: 5px;
  transition: color 0.3s ease-in-out;
}

.featureCard:hover .featureDescription {
  color: #4a4a4a;
}

.featureIcon {
  width: 20px;
  height: 20px;
  align-self: flex-end;
  transition: transform 0.3s ease-in-out;
}

.featureCard:hover .featureIcon {
  transform: translateX(5px);
}

@media (min-width: 768px) {
  .featureCard {
    padding: 15px;
  }

  .featureTitle {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .featureDescription {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  .featureIcon {
    width: 24px;
    height: 24px;
  }
}

@media (min-width: 1200px) {
  .featureCard {
    flex: 0 0 calc(50% - 20px);
    padding: 20px;
  }

  .featureTitle {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .featureDescription {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .featureIcon {
    width: 28px;
    height: 28px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .featureCard,
  .featureImage,
  .featureTitle,
  .featureDescription,
  .featureIcon {
    transition: none;
  }

  .featureCard:hover {
    transform: none;
  }
}