.recoverySteps {
  padding: 80px 40px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.title {
  font-size: 62px;
  line-height: 1.15;
  text-align: center;
  color: #000;
  margin-bottom: 80px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s ease-out forwards;
}

.bold {
  font-weight: 600;
}

.light {
  font-weight: 300;
  color: #6c6c6c;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.stepRight {
  justify-content: flex-end;
}

.stepLeft {
  justify-content: flex-start;
}

.stepContent {
  max-width: 300px;
}

.stepTitle {
  font-size: 24px;
  font-weight: 600;
  color: #3c3c3c;
  margin-bottom: 10px;
}

.stepDescription {
  font-size: 16px;
  line-height: 1.5;
  color: #6c6c6c;
}

.phoneImage {
  width: 203px;
  height: 437px;
  object-fit: cover;
  transition: transform 0.6s ease-out;
  margin-bottom: -3rem;
  margin-top: -3rem;
}

.dottedLine {
  width: 65%;
  height: 2px;
  border-top: 2px dashed #35abfa;
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.stepRight .stepContent {
  margin-right: 40px;
  text-align: right;
}

.stepLeft .stepContent {
  margin-left: 40px;
  text-align: left;
}

.fadeInRight {
  transform: translateX(50px);
}

.fadeInLeft {
  transform: translateX(-50px);
}

.animate {
  opacity: 1;
  transform: translateY(0) translateX(0);
}

.animate .phoneImage {
  transform: scale(1.05);
}

.animate + .dottedLine {
  opacity: 1;
  animation: drawLine 1s ease-out forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes drawLine {
  from {
    width: 0;
  }
  to {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .recoverySteps {
    padding: 40px 20px;
  }

  .title {
    font-size: 40px;
    margin-bottom: 40px;
  }

  .step {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px; /* Add space between steps */
  }

  .stepContent {
    max-width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  .phoneImage {
    width: 150px;
    height: 324px;
    margin-bottom: 0; /* Remove negative margin for mobile */
    margin-top: 0; /* Remove negative margin for mobile */
  }

  .dottedLine {
    width: 2px;
    height: 40px;
    border-top: none;
    border-left: 2px dashed #35abfa;
    margin: 20px 0; /* Add space around the dotted line */
  }

  .stepRight .stepContent,
  .stepLeft .stepContent {
    margin: 20px 0;
    text-align: center;
  }

  @keyframes drawLine {
    from {
      height: 0;
    }
    to {
      height: 40px;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .title,
  .step,
  .phoneImage,
  .dottedLine {
    animation: none;
    transition: none;
  }
}