.formSection {
    padding: 3rem 5vw;
    text-align: center;
    background: #FAFBFC;
  }
  
  .title {
    font-size: clamp(1.5rem, 4vw, 3rem);
    color: #1B4B66;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  .form {
    max-width: 1080px;
    margin: 0 auto;
  }
  
  .inputGroup {
    margin-bottom: 1.25rem;
  }
  
  .label {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
    color: #4A4A4A;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    font-weight: 500;
  }
  
  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input {
    width: 100%;
    padding: 0.875rem;
    padding-left: 2.75rem;
    border: 1px solid #E2E8F0;
    border-radius: 0.75rem;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    color: #1B4B66;
    background: white;
    transition: border-color 0.2s ease;
  }
  
  .input:focus {
    outline: none;
    border-color: #54A7F9;
  }
  
  .input::placeholder {
    color: #A0AEC0;
  }
  
  .icon {
    position: absolute;
    left: 0.875rem;
    color: #A0AEC0;
  }
  
  .formRow {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .checkboxInput {
    width: 1.125rem;
    height: 1.125rem;
    border: 2px solid #E2E8F0;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .checkboxLabel {
    color: #4A4A4A;
    font-size: clamp(0.75rem, 1.25vw, 0.875rem);
  }
  
  .submitButton {
    background: #54A7F9;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 0.75rem;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    transition: all 0.2s ease;
  }
  
  .submitButton:hover {
    background: #3d96f0;
    transform: translateY(-1px);
  }
  
  /* Phone input styles */
  .phoneInput {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .countryCode {
    position: absolute;
    left: 2.75rem;
    color: #1B4B66;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    z-index: 1;
  }
  
  .phoneInput .input {
    padding-left: 4.5rem;
  }
  
  /* Dropdown styles */
  .dropdownContainer {
    position: relative;
    width: 100%;
  }
  
  .dropdownButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-align: left;
    padding-right: 2.75rem;
  }
  
  .dropdownIcon {
    color: #A0AEC0;
    position: absolute;
    right: 0.875rem;
  }
  
  .dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #E2E8F0;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
    max-height: 12rem;
    overflow-y: auto;
  }
  
  .dropdownItem {
    padding: 0.875rem;
    padding-left: 2.75rem;
    cursor: pointer;
    color: #1B4B66;
    transition: background-color 0.2s ease;
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
  
  .dropdownItem:hover {
    background-color: #F7FAFC;
  }
  
  .placeholder {
    color: #A0AEC0;
  }
  
  @media (min-width: 640px) {
    .formRow {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .formSection {
      padding: 5rem 10vw;
    }
    
    .formRow {
      grid-template-columns: repeat(3, 1fr);
    }
  }