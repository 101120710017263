body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1 0 auto;
}

.contactSection {
  background: radial-gradient(50% 50% at 50% 50%, #6cb2f9 0%, #3a9af9 100%);
  padding: 71px 80px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.contactSection.visible {
  opacity: 1;
  transform: translateY(0);
  margin-top: 3rem;
}

.contactInfo {
  flex: 0 0 40%;
}

.logo {
  width: 187px;
  height: auto;
  margin-bottom: 45px;
}

.mission {
  font-size: 19px;
  line-height: 25px;
  margin-bottom: 45px;
}

.appStores {
  display: flex;
  gap: 8px;
  margin-bottom: 45px;
}

.appStoreButton,
.playStoreButton {
  width: 139px;
  height: auto;
}

.socialLinks {
  display: flex;
  gap: 25px;
}

.socialIcon {
  width: 25px;
  height: 25px;
}

.contactForm {
  flex: 0 0 50%;
  background-color: #fff;
  border-radius: 25px;
  padding: 60px 32px;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
  margin-top: -6rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formInput,
.selectInput {
  border-radius: 8px;
  background-color: #e9e9e9;
  padding: 19px;
  border: none;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.selectInput {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

.phoneInput {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 8px;
  padding: 0 19px;
}

.countryCode {
  color: #9a9a9a;
  margin-right: 9px;
}

.submitButton {
  width: 100%;
  border-radius: 25px;
  background-color: #005477;
  color: #fff;
  font-size: 20px;
  padding: 19px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #003a54;
}


.formRow {
  display: flex;
  gap: 13px;
}

.formRow > * {
  flex: 1;
}

.phoneInput .formInput {
  border-radius: 0 8px 8px 0;
  padding-left: 0;
}

.countryCode {
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

.selectInput {
  color: #333;
}

.selectInput option {
  color: #333;
  background-color: #fff;
}



@media (max-width: 1200px) {
  .contactSection {
    padding: 60px 40px;
  }
}

@media (max-width: 991px) {
  .contactSection {
    flex-direction: column;
    padding: 40px 20px;
  }

  .contactInfo,
  .contactForm {
    flex: 0 0 100%;
  }

  .contactForm {
    margin-top: 40px;
  }

  .logo {
    width: 150px;
    margin-bottom: 30px;
  }

  .mission {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .appStores {
    margin-bottom: 30px;
  }

  .appStoreButton,
  .playStoreButton {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .contactSection {
    padding: 30px 15px;
  }

  .contactForm {
    padding: 20px;
  }

  .formInput,
  .phoneInput,
  .selectInput {
    padding: 15px;
  }

  .submitButton {
    font-size: 18px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .formRow {
    flex-direction: column;
  }

  .phoneInput {
    flex-direction: row;
  }
}

@media (prefers-reduced-motion: reduce) {
  .contactSection {
    transition: none;
  }
}