.statsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 5vw;
    margin-top: -2rem;
  }
  
  .statCard {
    background: white;
    padding: 1.5rem;
    border-radius: 1.25rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    transition: transform 0.2s ease;
  }
  
  .statCard:hover {
    transform: translateY(-2px);
  }
  
  .statContent {
    flex: 1;
  }
  
  .statNumber {
    font-size: clamp(1.5rem, 4vw, 3rem);
    font-weight: 700;
    color: #1B4B66;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  .plus,
  .percent {
    color: #54A7F9;
    font-size: clamp(1.25rem, 3vw, 2.25rem);
    font-weight: 500;
  }
  
  .statLabel {
    font-size: clamp(0.875rem, 1.5vw, 1.125rem);
    color: #4A4A4A;
    margin-top: 0.375rem;
  }
  
  .arrow {
    width: 3rem;
    height: 3rem;
    background: #F5F8FA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
    transform: rotate(-45deg);
  }
  
  .arrowIcon {
    transform: rotate(45deg);
  }
  
  .statCard:hover .arrow {
    background: #EDF2F7;
  }
  
  @media (min-width: 640px) {
    .statsSection {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.25rem;
    }
  
    .statCard {
      width: calc(50% - 0.625rem);
    }
  }
  
  @media (min-width: 1024px) {
    .statsSection {
      flex-wrap: nowrap;
      gap: 1.5rem;
      padding: 2.5rem 10vw;
      margin-top: -2.5rem;
    }
  
    .statCard {
      width: calc(33.333% - 1rem);
    }
  }
  
  