.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin-top: 1rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  animation: fadeIn 0.5s ease-out;
}

.content {
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: #f6f6f600;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.headerButtons{
  display: flex;
  column-gap: 20px;
  margin-left: auto;
  margin-right: 0;
}

.logo {
  width: 133px;
  height: auto;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.downloadButton {
  border-radius: 5px;
  background-color: #005477;
  color: #fff;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.downloadButton:hover {
  background-color: #003a54;
  transform: translateY(-2px);
}

.downloadButton:active {
  transform: translateY(0);
}
.signInButton {
  border-radius: 5px;
  background-color: #005477;
  color: #fff;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.signInButton:hover {
  background-color: #003a54;
  transform: translateY(-2px);
}

.signInButton:active {
  transform: translateY(0);
}

/* Scroll effect classes */
.headerHidden {
  transform: translateY(-100%);
  opacity: 0;
}

.headerVisible {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 767px) {
  .content {
    padding: 15px 20px;
    max-width: 80%
  }
  
  .downloadButton {
    display: none;
  }
  
  .logo {
    width: 100px; /* Adjust size if needed for mobile */
  }
}

/* Optional: Add a subtle animation when the page loads */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
